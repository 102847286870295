import { CButton, CCardBody, CCardFooter, CCol, CRow, CAlert } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCopy } from '@coreui/icons';
import Button from 'components/common/Button';
import Modal, { useModal } from 'components/common/Modal';
import TextInput from 'components/inputs/TextInput';
import { FastField, Form, Formik } from 'formik';
import useCreateApplicationUser from 'hooks/applications/useCreateApplicationUser';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';
import s from './ApplicationName.module.scss';

const validationSchema = ({ t }) =>
  yup.object().shape({
    appName: yup
      .string()
      .required()
      .label(t({ id: 'applications.appName' })),
  });

const ApplicationForm = () => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <CRow>
        <CCol>
          <FastField isRequired name="appName" component={TextInput} label={t({ id: 'applications.appName' })} />
        </CCol>
      </CRow>
    </>
  );
};

const ApplicationFormik = ({ hideModal }) => {
  const createAapplicationUser = useCreateApplicationUser();
  const [applicationSuccessResult, setApplicationSuccessResult] = useState(null);

  const { formatMessage: t } = useIntl();

  const onSubmit = useCallback(
    async (values, helpers) => {
      try {
        const result = await createAapplicationUser(values);
        setApplicationSuccessResult(result);
        toast.success(t({ id: 'applications.add_application_success' }));
      } catch (error) {
        toastErrorMessage({ error, t });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [createAapplicationUser, t],
  );

  const initialValues = useMemo(() => {
    const values = {
      appName: '',
    };
    return values;
  });

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema({ t })}
    >
      {({ isSubmitting }) => (
        <Form>
          <CCardBody>
            <ApplicationForm />
            {!!applicationSuccessResult && (
              <CAlert color="success">
                <p>
                  Application id - {applicationSuccessResult.data.createApplicationUser._id}
                  <CButton
                    className={s.coppyToClipBoardButton}
                    color="success"
                    variant="outline"
                    onClick={() => copyToClipboard(applicationSuccessResult.data.createApplicationUser._id)}
                  >
                    <CIcon content={cilCopy} />
                  </CButton>
                </p>
                <hr />
                <p className="mb-0">
                  Application secret - {applicationSuccessResult.data.createApplicationUser.secret}
                  <CButton
                    className={s.coppyToClipBoardButton}
                    color="success"
                    variant="outline"
                    onClick={() => copyToClipboard(applicationSuccessResult.data.createApplicationUser.secret)}
                  >
                    <CIcon content={cilCopy} />
                  </CButton>
                </p>
              </CAlert>
            )}
          </CCardBody>
          <CCardFooter>
            <CRow className="justify-content-end" style={{gap: '4px'}}>
              <Button
                color="transparent"
                onClick={() => {
                  hideModal();
                }}
              >
                {t({ id: 'buttons.cancel' })}
              </Button>
              <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                {t({ id: 'buttons.submit' })}
              </Button>
            </CRow>
          </CCardFooter>
        </Form>
      )}
    </Formik>
  );
};

const ApplicationButton = ({}) => {
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: t } = useIntl();

  return (
    <>
      <CButton className={s.applicationButton} color="primary" variant="outline" onClick={showModal}>
        <div>{t({ id: 'applications.add_application_btn' })}</div>
      </CButton>
      <Modal isVisible={isVisible} close={hideModal}>
        <ApplicationFormik hideModal={hideModal} />
      </Modal>
    </>
  );
};

export default ApplicationButton;
