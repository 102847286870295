import gql from 'graphql-tag';
import get from 'lodash/get';
import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

export const GET_APPLICATIONS_API_LOGS = gql`
  query getAllApplications($limit: Int!, $offset: Int!) {
    allRestApiLogs(limit: $limit, offset: $offset) {
      count
      apiLogs {
        _id
        userId
        input
        output
        status
        errorMessage
        ip
        appName
        method
        useCase
        createdAt
      }
    }
  }
`;

const useGetAllApplicationsApiLogs = ({ currentPage }) => {
  const offset = COUNT_TABLE_RECORDS * (currentPage - 1);

  const { data, loading, error } = useQuery(GET_APPLICATIONS_API_LOGS, {
    variables: { limit: COUNT_TABLE_RECORDS, offset },
  });

  const applicationsApiLogs = useMemo(() => get(data, 'allRestApiLogs.apiLogs', []), [data]);
  const total = get(data, 'allRestApiLogs.count', 0);

  return { data: applicationsApiLogs, total, loading, error };
};

export default useGetAllApplicationsApiLogs;
