import { useMemo } from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

export const GET_APPLICATIONS = gql`
  query getAllApplications {
    allApplications {
      _id
      lastLoginAt
      deactivated
      roles
      profile {
        appName
      }
    }
  }
`;

const useGetAllApplications = () => {
  const { data, loading, error } = useQuery(GET_APPLICATIONS);

  const admins = useMemo(() => get(data, 'allApplications', []), [data]);

  return [admins, loading, error];
};

export default useGetAllApplications;
