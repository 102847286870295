import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_APPLICATIONS } from './useGetAllApplications';

const CREATE_APPLICATION_USER_MUTATION = gql`
  mutation createApplicationUser($profile: CreateApplicationProfileInput!) {
    createApplicationUser(profile: $profile) {
      _id
      secret
    }
  }
`;

const useCreateApplicationUser = () => {
  const [createUserMutation] = useMutation(CREATE_APPLICATION_USER_MUTATION);

  return useCallback(
    async ({ appName }) => {
      return await createUserMutation({
        variables: {
          profile: {
            appName,
          },
        },
        refetchQueries: [{ query: GET_APPLICATIONS }],
        awaitRefetchQueries: true,
      });
    },
    [createUserMutation],
  );
};

export default useCreateApplicationUser;
