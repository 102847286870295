/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { CBadge } from '@coreui/react';
import { useIntl } from 'react-intl';
import { CDataTable } from '@coreui/react';
import { formatDate } from 'utils/helpers';
import { COUNT_TABLE_RECORDS } from 'constants/constants';
import Pagination from 'components/common/Pagination';
import ApplicationsApiLogDetailButton from './ApplicationsApiLogDetailButton';

const fields = (t) => [
  { key: 'appName', label: t({ id: 'applications_api_logs.app_name' }) },
  { key: 'useCase', label: t({ id: 'applications_api_logs.use_case' }) },
  { key: 'ip', label: t({ id: 'applications_api_logs.ip' }) },
  { key: 'method', label: t({ id: 'applications_api_logs.method' }) },
  { key: 'createdAt', label: t({ id: 'applications_api_logs.date' }) },
  { key: 'status', label: t({ id: 'applications_api_logs.status' }) },
  { key: 'actions', label: '' },
];

const ApplicationsApiLogsTable = ({ applicationsApiLogs, loading, changePage, currentPage, total }) => {
  const { formatMessage: t } = useIntl();
  const applicationFields = useMemo(() => fields(t), [t]);

  return (
    <>
      <CDataTable
        fields={applicationFields}
        hover
        items={applicationsApiLogs}
        itemsPerPage={COUNT_TABLE_RECORDS}
        loading={loading}
        size="sm"
        scopedSlots={{
          createdAt: ({ createdAt }) => <td>{createdAt ? formatDate(createdAt) : undefined}</td>,
          status: ({ status }) => <td><CBadge color={status == 200 ? 'success' : 'danger'}>{status}</CBadge></td>,
          actions: (data) => (
            <td>
              <ApplicationsApiLogDetailButton data={data} />
            </td>
          ),
        }}
      />
      <Pagination {...{ changePage, currentPage, total }} />
    </>
  );
};

export default ApplicationsApiLogsTable;
