/* eslint-disable no-template-curly-in-string */
export default {
  auth: {
    email: 'Email',
    name: 'Name',
    password: 'Passwort',
    sign_in_submit: 'Anmelden',
    sign_in_message: 'Melden Sie sich mit Ihrem Account an',
    verification_code: 'Verification-Code',
    verification_code_tip:
      'Über die Google Authenticator App erhalten Sie einen 6-stelligen Code, der hier einzugeben ist',
    activate_authenticator: 'Authenticator aktivieren',
  },
  user: {
    first_name: 'Vorname',
    last_name: 'Nachname',
    user_not_found: 'User nicht gefunden',
    cannot_remove_yourself: 'Sie können sich nicht selbst löschen',
  },
  app: {
    back: 'Zurück',
    submit_form_final: 'Berechnen',
    submit_form_next: 'Nächster',
    send: 'Abschicken',
  },
  errors: {
    fill_field: 'Bitte dieses Feld ausfüllen!',
    choose_one: 'Bitte Auswahl treffen',
    no_server_response:
      'Verbindung zum Server derzeit nicht möglich, bitte Internetverbindung überprüfen bzw. später nochmals versuchen',
    email_required: 'Email-Adresse ist erforderlich!',
    password_required: 'Passwort ist erforderlich!',
    required: 'erforderlich',
    email_invalid: 'Email-Adresse ungültig',
    server_connection_error: 'Verbindung zum Server - nicht möglich.',
  },
  page_not_found: {
    title: 'Seite nicht gefunden',
    back: 'Zurück',
  },
  buttons: {
    save: 'Sichern',
    submit: 'Überprüfen',
    cancel: 'Abbrechen',
    back: 'Zurück',
    unassign: 'Nicht zugewiesen',
    delete: 'Löschen',
  },
  init2FA: {
    configure_code_generator: 'Google Authenticator konfigurieren',
    steps:
      '<ol><li>Download der Google Authenticator App am Smartphone von </li>the <a>href={playMarketLink} Play Market</a> or the <a>href={appStoreLink} App Store</a> <li>In unserer Anwendung erstellen Sie einen Benutzer und melden Sie diesem dem internen Produktmanager</li><li>Wählen Sie Scan Barcode</li><li>Sie erhalten von der Authenticator App einen 6-stelligen Code</li><li>Geben diesen hier ein und drücken Sie "Überprüfen".\n Wenn der Code korrekt ist, bekommen Sie in in den Admin-Bereich.\n Fall der Code nicht korrekt ist, bitte nochmals versuchen.</li></ol>',
  },
  project_pdf_requests: {
    title: 'Projekt-PDF-Anfragen',
    filter: 'Filter',
    email: 'Email',
    name: 'Name',
    projectName: 'Projektname',
    clientIP: 'Client IP',
    created_at: 'Angelegt',
    pdf: 'PDF',
    utmParams: 'UTM params',
    phone_number: '@Phone number',
    project_implementation_start_point: {
      label: '@Project implementation start point',
      options: {
        within_three_months: 'Innerhalb von 3 Monaten',
        in_three_to_six_months: 'In 3 bis 6 Monaten',
        later_than_in_six_months: 'Später als in 6 Monaten',
      },
    },
  },
  messages: {
    feedback: 'Feedback',
    userMessage: 'Ihre Nachricht',
    responded: 'Beantwortet',
    unresponded: 'Unbeantwortet',
    filter: 'Filter',
    message_sent: 'Nachricht wurde gesendet',
    empty: 'Keine Nachrichten',
    name: 'Name',
    message: 'Nachricht',
    date: 'Datum',
    placeholder_for_editor: 'Schreiben Sie hier Ihre Nachricht, oder fügen Sie diese mit copy & paste ein',
  },
  projects: {
    utmParams: '@UTM Params',
    filter: 'Filter',
    name: 'Name',
    price: 'Preis',
    pdf: 'Pdf',
    excel: 'Excel',
    created: 'Angelegt',
    last_updated: 'Zuletzt geändert',
    xlsx_export: 'Export Xlsx',
    clientIP: 'Client IP',
    archived: 'Archiviert',
    types: {
      new_building: 'Neubau eines Einfamilienhauses',
      new_apartment: 'Kauf einer Wohnung',
      buying_house: 'Kauf Einfamilienhaus (inkl. Sanierung)',
      renovation_house: 'CO₂-Bilanz & Sanierung Haus',
      property_valuation: 'Immobilienbewertung',
      lifestyle_calculator: 'Lifestyle Kalkulator',
    },
  },
  loan_consultants: {
    title: 'Kreditberater',
    loan_consultant_added: 'Kreditberater hinzugefügt',
    delete_loan_consultant: 'Kreditberater-Rolle löschen',
    delete_loan_consultant_message:
      'Sind Sie sicher, dass die Berater-Rolle für diesen Berater gelöscht werden soll: - {name}',
    loan_consultant_deleted: 'Berater-Rolle wurde gelöscht',
    delete: 'Löschen',
    download_btn: 'Bericht downloaden',
    add_loan_consultant_btn: 'Kreditberater hinzufügen',
    name: 'Name',
    email: 'Email',
  },
  admins: {
    title: 'Admins',
    add_admin_btn: 'Admin hinzufügen',
    edit_admin_btn: 'Ändern',
    add_admin_success: 'Admin wurde hinzugefügt',
    edit_admin_info_success: 'Admin-Informationen wurden bearbeitet',
    active: 'Aktiv',
    lastLogin: 'Letzer login',
    name: 'Name',
    email: 'Email',
    firstName: 'Vorname',
    lastName: 'Nachname',
    password: 'Passwort',
  },
  applications: {
    id: 'ID',
    title: 'Applications',
    add_application_btn: 'Add application',
    lastLogin: 'Last request',
    name: 'Application name',
    appName: 'Application Name',
    add_application_success: 'Application has been added',
    active: 'Active',
  },
  applications_api_logs: {
    title: 'Applications API logs',
    id: 'ID',
    user_id: 'Application ID',
    date: 'Date',
    status: 'Satus',
    details: 'Details',
    input: 'Input',
    result: 'Result',
    use_case: 'Use-case',
    method: 'HTTP Method',
    ip: 'IP',
    app_name: 'Application Name',
  },
  notifications: {
    empty: 'Keine Benachrichtigungen',
    message: 'Nachricht',
    date: 'Datum',
  },
  select_values: {
    not_important: 'Nicht wichtig',
    important: 'Wichtig',
    very_important: 'Sehr wichtig',
  },
  financing_requests: {
    project_name: 'Projektname',
    email: 'Email',
    sender: 'Sender',
    created_at: 'Erstellt am',
    user: 'User',
    message: 'Nachricht',
    empty: 'No requests',
    download: 'Download als pdf',
  },
  customer_data_analytics: {
    generate_report: 'Bericht erstellen',
    customer_name: 'Kundenname',
    customer_email: 'Kunden-Mail',
    ip_address: 'IP-Addresse',
    alone_or_partner: 'Partnerschaft',
    age: 'Geb-Dat',
    created_at: 'Erstellt',
    kids_quantity: 'Kinder',
    monthly_net_salary: 'Einkommen',
    monthly_net_salary_partner: 'Einkommen Partner',
    other_revenues: 'Sonstige Einkünfte',
    amount_with_loan_costs: 'Kreditbetrag',
    last_project_type: 'Projekttyp',
    last_project_created_at: '@Last project created at',
    new_car: 'Neues Auto',
    savings: 'Sparen',
    luxuries: 'Shopping, Beauty',
    hobbies: 'Hobbies',
    pension_and_insurances: 'Vorsorge und Versicherung',
    socializing: 'Kulinarisches, Gäste',
    taking_a_break: 'Reisen',
    technology: 'Technologie',
    pdf: 'PDF',
  },
  co2_report: {
    category_name: 'CO₂ relevante Kategorie',
    user_input: 'Geänderter Wert',
    number_of_status_planned: `Anzahl im Status 'geplant'`,
    co2_savings: 'CO₂ Einsparung t/a',
    solar_power_system: 'PV-Anlagen',
    heating_exchange: 'Heizungstausch',
    window_exchange: 'Fenstertausch',
    facade_insulation: 'Fassadendämmung',
    new_windows: 'Fenstertausch',
    insulation_top_ceiling: 'Dämmung o. Geschossdecke',
    insulation_basement_ceiling: 'Dämmung Kellerdecke',
    insulation_facade: 'Fassadendämmung',
    controlled_living_space_ventilation: 'Kontrollierte Wohnraumlüftung',
    heating_system: 'Heizungstausch',
    go_to_details: 'Details..',
    back_to_co2_report: 'Zurück zum CO₂-Bericht',
    username: 'User-Name',
    energy_ghg_balance_before: 'Energie-Bilanz (CO2) vor der Planung',
    energy_ghg_balance_after: 'Energie-Bilanz (CO2) nach der Planung',
  },
  hypo_noe_api_request: {
    project_name: '@Project name',
    project_type: '@Project type',
    details: '@Details',
    purchase_price: '@Purchase price',
    parameter: '@Parameter',
    value: '@Value',
    customers: '@Customers',
    status: '@Status',
    is_ok: '@Is ok?',
    error: '@Error',
    user_info: '@User info',
    guest: '@Guest',
    created_at: '@Created at',
    retry_request: '@Retry request',
    request_not_found: '@Request not found',
  },
  iFrameSettings: {
    add_allowed_origin_btn: '@Add origin',
    add_allowed_origin_success: '@Allowed origin has been added',
    delete_allowed_origin: {
      title: '@Delete allowed origin',
      message: '@Are you sure, you want to delete the "{value}" allowed origin',
      success: '@Allowed origin has been deleted',
    },
    allowed_origin: '@Allowed origin',
    unique: '@${path} must be unique',
    url: '@${path} must be a valid URL',
    duplicated: '@The allowed origin is already added',
  },
  apiLogs: {
    table: {
      headers: {
        eventType: '@Event type',
        method: '@Method',
        url: '@Url',
        statusCode: '@Status code',
        data: '@Data',
        details: '@Details',
        createdAt: '@CreatedAt',
      },
    },
  },
  page_titles: {
    login: 'Anmelden',
    projects: 'Projekte',
    analytics: 'Analytics',
    logout: 'Abmelden',
    applications: 'Applications',
    applicationsApiLogs: 'Applications API logs',
    admins: 'Administratoren',
    messages: 'Nachrichten',
    login2FA: 'Authenticator',
    notifications: 'Notifications',
    financing_requests: 'Finanzierungsanfragen',
    loan_consultants: 'Kreditberater',
    project_pdf_requests: 'PDF-Anfragen',
    customer_data_analytics: 'Kundenanalyse',
    co2: 'CO2',
    co2_report: 'Reporting',
    co2_projects: 'Projekte',
    hypo_noe_api_requests_report: '@HYPO-NOE API requests',
    iFrameSettings: '@iFrame settings',
    priceHubbleApiLogs: 'Price Hubble API logs',
    effiApiLogs: '@Effi API logs',
  },
};

export const backendErrors = {
  access_denied: 'Zugriff nicht möglich, bitte etwas später nochmals versuchen',
  something_went_wrong: 'Sorry, etwas lief falsch - bitte etwas später nochmals versuchen',
  form_data_is_missing: 'Eingaben im Formular fehlen',
  auth: {
    login_failed: 'Login fehlgeschlagen - bitte nochmals versuchen',
    email_exists: 'Es existiert bereits ein User mit dieser Email-Adresse',
    verify_account: 'Email-Adresse muss noch bestätigt werden',
    password_is_required: 'Passwort eingeben',
    not_authorized: 'Nicht berechtigt',
    temporary_password_is_required: 'Für Ihr Erstlogin benötigen Sie das zugesendete Start-Passwort',
    wrong_password: 'Falsches Passwort',
    account_has_no_password: 'Ihr Account hat kein Passwort da Sie sich mit Google oder Facebook registriert haben',
  },
  invitation: {
    email_exists: 'Mit dieser Email-Adresse gibt es bereits einen User',
    cannot_resend_temp_password: `Sie können kein neues Startpasswort versenden, da der User/Kunde es bereits geändert hat`,
    cannot_delete_invited_user: `Sie können den Kunden-Account nicht löschen, da der Kunde bereits das Startpasswort geändert`,
  },
  admin: {
    disable_2_fa: 'Disable 2FA before initiating it again.',
    otp_is_invalid: 'One-time password is not valid. Please try again',
    last_admin: 'Sie sind der letzte Admin',
    otp_is_required: 'Bitte das Einmal-Passwort eingeben',
  },
  hypo_noe_api_request: {
    request_not_found: '@Request not found',
    retry_is_not_available: '@Unfortunately, you could not resubmit this request because it was successful',
  },
  user: {
    cannot_deactivate_himself: 'Sie können sich nicht selbst deaktivieren',
    user_not_found: '@User not found',
  },
  project: {
    not_found: '@Project not found',
  },
};
