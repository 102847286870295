import { CButton, CCardBody, CCardFooter, CCol, CRow } from '@coreui/react';
import Button from 'components/common/Button';
import Modal, { useModal } from 'components/common/Modal';
import TextInput from 'components/inputs/TextInput';
import { FastField, Form, Formik } from 'formik';
import useCreateAdmin from 'hooks/admins/useCreateAdmin';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { toastErrorMessage } from 'utils/helpers';
import { toast } from 'react-toastify';
import useUpdateUser from 'hooks/admins/useUpdateUser';

const validationSchema = ({ isCreating, t }) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required()
      .label(t({ id: 'admins.firstName' })),
    lastName: yup
      .string()
      .required()
      .label(t({ id: 'admins.lastName' })),
    ...(isCreating
      ? {
          email: yup
            .string()
            .email()
            .required()
            .label(t({ id: 'admins.email' })),
          password: yup
            .string()
            .min(8)
            .required()
            .label(t({ id: 'admins.password' })),
        }
      : {}),
  });

const AdminForm = ({ isCreating }) => {
  const { formatMessage: t } = useIntl();
  return (
    <>
      <CRow>
        <CCol md="6">
          <FastField isRequired name="firstName" component={TextInput} label={t({ id: 'admins.firstName' })} />
        </CCol>
        <CCol md="6">
          <FastField isRequired name="lastName" component={TextInput} label={t({ id: 'admins.lastName' })} />
        </CCol>
      </CRow>
      {isCreating ? (
        <>
          <FastField isRequired name="email" component={TextInput} label={t({ id: 'admins.email' })} />
          <FastField
            isRequired
            name="password"
            type="password"
            component={TextInput}
            label={t({ id: 'admins.password' })}
          />
        </>
      ) : null}
    </>
  );
};

const AdminFormik = ({ user, hideModal }) => {
  const createAdmin = useCreateAdmin();
  const updateUser = useUpdateUser();

  const { formatMessage: t } = useIntl();

  const initialValues = useMemo(() => {
    const values = {
      firstName: user?.profile.firstName ?? '',
      lastName: user?.profile.lastName ?? '',
    };
    if (!user) Object.assign(values, { email: '', password: '' });
    return values;
  }, [user]);

  const onSubmit = useCallback(
    async (values, helpers) => {
      try {
        if (!user) {
          await createAdmin(values);
          toast.success(t({ id: 'admins.add_admin_success' }));
        } else {
          await updateUser({ _id: user._id, ...values });
          toast.success(t({ id: 'admins.edit_admin_info_success' }));
        }
        hideModal();
      } catch (error) {
        toastErrorMessage({ error, t });
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [user, hideModal, createAdmin, t, updateUser],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema({ isCreating: !user, t })}
    >
      {({ isSubmitting }) => (
        <Form>
          <CCardBody>
            <AdminForm isCreating={!user} />
          </CCardBody>
          <CCardFooter className="text-right">
            <Button
              color="transparent"
              onClick={() => {
                hideModal();
              }}
            >
              {t({ id: 'buttons.cancel' })}
            </Button>{' '}
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
              {t({ id: 'buttons.submit' })}
            </Button>
          </CCardFooter>
        </Form>
      )}
    </Formik>
  );
};

const AdminButton = ({ user }) => {
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: t } = useIntl();

  return (
    <>
      {user ? (
        <CButton color="primary" variant="outline" onClick={showModal}>
          {t({ id: 'admins.edit_admin_btn' })}
        </CButton>
      ) : (
        <Button color="primary" onClick={showModal}>
          {t({ id: 'admins.add_admin_btn' })}
        </Button>
      )}
      <Modal isVisible={isVisible} close={hideModal}>
        <AdminFormik user={user} hideModal={hideModal} />
      </Modal>
    </>
  );
};

export default AdminButton;
