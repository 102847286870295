import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CDataTable } from '@coreui/react';
import useChangeApplicationAccountActivationStatus from 'hooks/applications/useChangeApplicationAccountActivationStatus';
import { SimpleSwitchInput } from 'components/inputs/Switch';
import { formatDate, toastErrorMessage } from 'utils/helpers';
import { COUNT_TABLE_RECORDS } from 'constants/constants';

const fields = (t) => [
  { key: '_id', label: t({ id: 'applications.id' }) },
  { key: 'name', label: t({ id: 'applications.name' }) },
  { key: 'lastLogin', label: t({ id: 'applications.lastLogin' }) },
  { key: 'active', label: t({ id: 'applications.active' }) },
];

const ApplicationsTable = ({ applications, loading }) => {
  const { formatMessage: t } = useIntl();
  const changeApplicationAccountActivationStatus = useChangeApplicationAccountActivationStatus();
  const applicationFields = useMemo(() => fields(t), [t]);

  return (
    <CDataTable
      fields={applicationFields}
      hover
      items={applications}
      itemsPerPage={COUNT_TABLE_RECORDS}
      loading={loading}
      size="sm"
      scopedSlots={{
        name: ({ profile }) => <td>{`${profile?.appName}`}</td>,
        active: ({ deactivated, _id }) => (
          <td>
            <SimpleSwitchInput
              onChange={async () => {
                try {
                  await changeApplicationAccountActivationStatus({ deactivated, _id });
                } catch (error) {
                  toastErrorMessage({ error, t });
                }
              }}
              checked={!deactivated}
            />
          </td>
        ),
        lastLogin: ({ lastLoginAt }) => <td>{lastLoginAt ? formatDate(lastLoginAt) : undefined}</td>,
      }}
    />
  );
};

export default ApplicationsTable;
