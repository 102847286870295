import routePaths from 'router/route-paths';

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.projects',
    to: routePaths.projects,
    icon: 'cil-list-rich',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.admins',
    to: routePaths.admins,
    icon: 'cil-people',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.applications',
    to: routePaths.applications,
    icon: 'cil-devices',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.project_pdf_requests',
    to: routePaths.projectPDFRequests,
    icon: 'cil-file',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'page_titles.co2',
    icon: 'cil-graph',
    items: [
      {
        _tag: 'CSidebarNavItem',
        name: 'page_titles.co2_report',
        to: routePaths.co2SavingsReport,
        icon: 'cil-chart-line',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'page_titles.co2_projects',
        to: routePaths.co2SavingsByProjects,
        icon: 'cil-bar-chart',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.iFrameSettings',
    to: routePaths.iFrameSettings,
    icon: 'cil-settings',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.priceHubbleApiLogs',
    to: routePaths.priceHubbleApiLogs,
    icon: 'cil-list-rich',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.effiApiLogs',
    to: routePaths.effiApiLogs,
    icon: 'cil-list-rich',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.applicationsApiLogs',
    to: routePaths.applicationsApiLogs,
    icon: 'cil-list-rich',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'page_titles.logout',
    to: routePaths.logout,
    icon: 'cil-exit-to-app',
    className: 'mt-auto',
  },
];
