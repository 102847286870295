import React, { useMemo } from 'react';
import ApplicationsApiLogsTable from 'components/applicationsApiLogs/ApplicationsApiLogsTable';
import 'components/common/AlertOverlay.scss';
import useGetAllApplicationsApiLogs from 'hooks/applicationsApiLogs/useGetAllApplicationsApiLogs';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { CCard, CCardBody, CCardHeader, CCol } from '@coreui/react';
import usePagination from 'hooks/usePagination';
import { useIntl } from 'react-intl';

const ApplicationPage = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination();
  const { data, total, loading, error } = useGetAllApplicationsApiLogs({
    currentPage,
  });

  const applicationsApiLogs = useMemo(() => {
    return data.map(
      ({ _id, userId, createdAt, input, output, status, errorMessage, ip, appName, method, useCase }) => ({
        _id,
        userId,
        createdAt,
        input,
        output,
        status,
        errorMessage,
        ip,
        appName,
        method,
        useCase,
      }),
    );
  }, [data]);

  return (
    <CCol>
      <CCard>
        <CCardHeader>
          <h4>{t({ id: 'applications_api_logs.title' })}</h4>
        </CCardHeader>

        <CCardBody>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <ApplicationsApiLogsTable
              applicationsApiLogs={applicationsApiLogs}
              loading={loading}
              {...{ total, currentPage, changePage }}
            />
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default ApplicationPage;
