import { useCallback } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GET_APPLICATIONS } from './useGetAllApplications';

const CHANGE_APPLICATION_ACCOUNT_ACTIVATION_STATUS_MUTATION = gql`
  mutation changeUserAccountActivationStatus($_id: ID!, $activationStatus: UserAccountActivationStatus!) {
    changeUserAccountActivationStatus(_id: $_id, activationStatus: $activationStatus)
  }
`;

const useChangeApplicationAccountActivationStatus = () => {
  const [changeApplicationAccountActivationStatus] = useMutation(CHANGE_APPLICATION_ACCOUNT_ACTIVATION_STATUS_MUTATION);

  const handleChangeApplicationAccountActivationStatus = useCallback(
    async ({ deactivated, _id }) => {
      await changeApplicationAccountActivationStatus({
        variables: { _id, activationStatus: deactivated ? 'activated' : 'deactivated' },
        refetchQueries: [{ query: GET_APPLICATIONS }],
        awaitRefetchQueries: true,
      });
    },
    [changeApplicationAccountActivationStatus],
  );

  return handleChangeApplicationAccountActivationStatus;
};

export default useChangeApplicationAccountActivationStatus;
