import React, { useMemo } from 'react';
import ApplicationsTable from 'components/applications/ApplicationsTable';
import 'components/common/AlertOverlay.scss';
import useGetAllApplications from 'hooks/applications/useGetAllApplications';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { CCard, CCardBody, CCardHeader, CCol } from '@coreui/react';
import ApplicationButton from 'components/applications/ApplicationButton';
import { useIntl } from 'react-intl';
import s from './ApplicationsPage.module.scss';

const ApplicationPage = () => {
  const { formatMessage: t } = useIntl();
  const [data, applicationsLoading, applicationsError] = useGetAllApplications();

  const applications = useMemo(() => {
    return data.map(({ _id, profile, deactivated, lastLoginAt }) => ({
      _id,
      deactivated,
      profile,
      lastLoginAt,
    }));
  }, [data]);

  return (
    <CCol>
      <CCard>
        <CCardHeader className={s.headerWrapper}>
          <h4>{t({ id: 'applications.title' })}</h4>
          <div>
            <ApplicationButton>{t({ id: 'applications.add_application_btn' })}</ApplicationButton>
          </div>
        </CCardHeader>

        <CCardBody>
          {applicationsError ? (
            <AlertGraphQLError error={applicationsError} />
          ) : (
            <ApplicationsTable applications={applications} loading={applicationsLoading} />
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default ApplicationPage;
