import { CButton, CCardBody, CCol, CRow, CBadge, CTextarea, CLabel } from '@coreui/react';
import Modal, { useModal } from 'components/common/Modal';
import React from 'react';
import { useIntl } from 'react-intl';
import s from './ApplicationsApiLogDetailButton.module.scss';

const pretifyJSON = (jsonString) => {
  const obj = JSON.parse(jsonString);
  const pretified = JSON.stringify(obj, undefined, 4);
  return pretified;
};

const ApplicationApiLogDetail = ({ data }) => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <CCardBody>
        <CRow>
          <CCol lg={6}>
            <CRow>
              <CLabel className={s.label}>{t({ id: 'applications_api_logs.input' })}</CLabel>
            </CRow>
            <CRow>
              <CTextarea rows={45}>{pretifyJSON(data.input)}</CTextarea>
            </CRow>
          </CCol>
          <CCol lg={6}>
            <CRow>
              <CLabel className={s.label}>
                {t({ id: 'applications_api_logs.result' })}{' '}
                <CBadge color={data.status == 200 ? 'success' : 'danger'}>{data.status}</CBadge>
              </CLabel>
            </CRow>
            <CRow>
              <CTextarea rows={45}>{data.errorMessage || pretifyJSON(data.output)}</CTextarea>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </>
  );
};

const ApplicationButton = ({ data }) => {
  const { showModal, hideModal, isVisible } = useModal();
  const { formatMessage: t } = useIntl();

  return (
    <>
      <CButton color="primary" variant="outline" onClick={showModal}>
        {t({ id: 'applications_api_logs.details' })}
      </CButton>
      <Modal
        classNamesOverride={{
          bodyOpenClassName: s.bodyOpen,
          className: {
            base: s.modal,
            afterOpen: s.modalAfterOpen,
            beforeClose: s.modalBeforeClose,
          },
          overlayClassName: {
            base: s.overlay,
            afterOpen: s.overlayAfterOpen,
            beforeClose: s.overlayBeforeClose,
          },
        }}
        isVisible={isVisible}
        close={hideModal}
      >
        <ApplicationApiLogDetail data={data} />
      </Modal>
    </>
  );
};

export default ApplicationButton;
